<template>
    <div>
      <el-table :data="infoList" border style="width: 100%">
        <el-table-column prop="client_ip" label="来源IP" width="300"> </el-table-column>
        <el-table-column prop="user_name" label="用户名" width="220">
        </el-table-column>
        <el-table-column prop="message" label="登录响应信息">
        </el-table-column>
        <el-table-column prop="status" label="状态" width="91"
          ><template slot-scope="scope">
            <el-tag
              :type="
                scope.row.status == 1
                  ? 'success'
                  : 'danger'
              "
              >{{
                scope.row.status == 1
                ? '登录成功'
                  : '登录失败'
              }}</el-tag
            ></template
          >
        </el-table-column>
        <el-table-column prop="create_time" width="160" label="创建时间"
          ><template slot-scope="scope">
            <span>{{ timestampToTime(scope.row.create_time) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </template>
  
  <script>
  export default {
    name: "LogLoginSelect",
    data() {
      return {
        //分页数据--start
        pageSize: 10,
        pageNum: 1,
        total: 0,
        //分页数据--end
        infoList: [],
        form: {},
        //日期
        date:"",
      };
    },
    methods: {
      getInfo() {
        this.$store
          .dispatch("logLogin/select", {
            pagesize: this.pageSize,
            pagenum: this.pageNum,
            date: this.date,
          })
          .then((res) => {
            var { data } = res;
            this.infoList = data.info_list;
            this.total = data.total;
          });
      },
      handleGetLogLoginInfo(data){
        this.date = data
        this.getInfo()
      },
      //分页控件函数--start
      handleSizeChange(val) {
        this.pageSize = val;
        this.getInfo();
      },
      handleCurrentChange(val) {
        this.pageNum = val;
        this.getInfo();
      },
      //分页控件函数--end
      //时间戳转换为时间
      timestampToTime(timestamp) {
        var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var D =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
        var h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
        var m =
          (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
          ":";
        var s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      },
    },
  };
  </script>
  
  <style>
  </style>