<template>
  <el-card class="info_card">
    <div slot="header">
      <el-row type="flex" justify="space-between"
        ><el-col :span="10"><span>登录日志管理</span></el-col>
        <el-col :span="10">
          <el-date-picker v-model="date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="primary"
            round
            icon="el-icon-search"
            @click="getInfo()"
            :loading="isLoading"
            >查询</el-button
          ></el-col
        ></el-row
      >
    </div>
    <LogLoginSelect ref="LogLoginSelect"></LogLoginSelect>
  </el-card>
</template>
  
  <script>
  import moment from "moment"
import LogLoginSelect from "./LogLoginSelect/index.vue";
export default {
  components: { LogLoginSelect },
  data() {
    return {
      isLoading: false,
      date: moment(new Date()).format('yyyy-MM-DD'),
    };
  },
  mounted(){
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.$refs.LogLoginSelect.handleGetLogLoginInfo(this.date);
    },
  },
};
</script>
  
<style scoped>
.info_card ::v-deep .el-card__body {
  padding: 0px;
}
</style>